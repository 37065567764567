import { Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '../shared/Button';
import SelectOption from '../shared/SelectOption';
import { ReactComponent as SeniorIcon } from '../../assets/svgs/senior.svg';
import { ReactComponent as JuniorIcon } from '../../assets/svgs/junior.svg';
import { ReactComponent as SophomoreIcon } from '../../assets/svgs/sophomore.svg';
import { ReactComponent as FreshmenIcon } from '../../assets/svgs/freshmen.svg';
import { InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import Dialog from '../shared/Dialog';
import { InputField } from '../shared/InputField';
import useThemeStore from '../../stores/ThemeStore';
import { Checkbox } from '../shared/Checkbox';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
const MyAccountDetailsForm = ({
  firstName = '',
  lastName = '',
  phoneNumber = '',
  email = '',
  schoolName = '',
  schoolLocation = '',
  address = {
    line2: '',
    state: '',
    city: '',
    postal_code: '',
    country: '',
    line1: '',
  },
  grade = '',
  highschoolLink = '',
  receiveNotifications = false,
  open,
  onClose = () => {},
  onSave = () => {},
  onChange = () => {},
  loggedInType = '',
  onChangePassword = () => {},
  linkGoogleAccount = () => {},
  isParent,
}) => {
  const phoneNumberRegex =
    /^(?:\(\d{3}\)\s*|\d{3}(?:-|\.)?)\d{3}(?:-|\.)?\d{4}$/;
  const [error, setError] = useState({});
  const { isMobile } = useThemeStore();
  const [isDisabled, setIsDisabled] = useState(true);
  const [userData, setUserData] = useState({
    firstName: firstName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || '',
    schoolName: schoolName || '',
    address: address || '',
    grade: grade || '',
    highschoolLink: highschoolLink || '',
    schoolLocation: schoolLocation || '',
    receiveNotifications: receiveNotifications || false,
  });

  const gradeMenuData = [
    {
      id: 1,
      grade: '9',
      label: 'Freshman | Grade 9',
      icon: <FreshmenIcon />,
    },
    {
      id: 2,
      grade: '10',
      label: 'Sophomore | Grade 10',
      icon: <SophomoreIcon />,
    },
    {
      id: 3,
      grade: '11',
      label: 'Junior | Grade 11',
      icon: <JuniorIcon />,
    },
    {
      id: 4,
      grade: '12',
      label: 'Senior | Grade 12',
      icon: <SeniorIcon />,
    },
  ];

  useEffect(() => {
    if(userData.highschoolLink) {
      checkIfValidUrl(userData.highschoolLink);
    }
  }, [userData.highschoolLink]);


  function checkIfValidUrl(url) {
    // check if the link is a valid url for https and http and www
    if(url) {
      if (url.startsWith("https://") ||
          url.startsWith("http://") ||
          url.startsWith("www."))
      {
          setError({});
          setIsDisabled(false);
      } else {
          setError({
            field: 'highschoolLink',
            message: 'Please enter a valid URL',
          });
          setIsDisabled(true);
      }
    } else {
      setError({});
      setIsDisabled(false);
    }
  }

  function validateAndSave() {
    // check if the highschool link is a valid url
    // if it starts with https:// or http:// then save don't do anything
    // if it starts with www. then add https:// to the link and save
    if(userData.highschoolLink) {
      if (userData.highschoolLink.startsWith("https://") ||
          userData.highschoolLink.startsWith("http://") )
      {
        onSave(userData);
      } else {
        userData.highschoolLink = `https://${userData.highschoolLink}`;
        onSave(userData);
      }
    }
  }

  return (
    <Dialog
      open={open}
      onCancel={onClose}
      continueText="Update"
      onContinue={() => {
        validateAndSave();
        onClose();
      }}
      disabled={isDisabled}
      title="Edit profile"
      isHeight={isMobile && true}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-6">
          <div className="gap-4 flex flex-col sm:grid sm:grid-cols-3">
            <TextField
              value={userData?.firstName}
              required
              id="outlined-name"
              label="First Name"
              className="col-span-1"
              onChange={(event) =>
                setUserData({ ...userData, firstName: event.target.value })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 4px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
            <TextField
              value={userData?.lastName}
              required
              id="outlined-name"
              label="Last Name"
              className="col-span-1"
              onChange={(event) =>
                setUserData({ ...userData, lastName: event.target.value })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 4px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />

            <PhoneInput
              isValid={(value) => {
                // Regular expression for a phone number with a country code
                const phoneRegex = /\d{1,3}\s?\d{1,14}(\s?\d{1,14})?$/;
                return phoneRegex.test(value);
              }}
              specialLabel="Phone Number (Optional)"
              placeholder="Enter"
              country={'us'}
              value={userData?.phoneNumber}
              onChange={(value) => {
                console.log('value', value);

                setUserData({ ...userData, phoneNumber: `+${value}` });
              }}
              inputClass="border rounded-md border-gray-300 px-1 py-2 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
              containerClass="phoneInput"
              inputStyle={{
                width: '100%',
                height: '100%',
                padding: '0px 14px 0px 58px',
                transition: 'none',
                minHeight: '3.325rem',
              }}
            />
          </div>
          <div className="flex flex-col sm:grid sm:grid-cols-6 gap-4">
            <TextField
              value={userData?.schoolName}
              id="outlined-name"
              label="High school name"
              placeholder="Tell us where you attend high school"
              className="col-span-2"
              onChange={(event) =>
                setUserData({ ...userData, schoolName: event.target.value })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 12px',
                  },
                },
              }}
              fullWidth={isParent ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
            <TextField
              value={userData?.schoolLocation}
              id="outlined-name"
              label="City/State (Optional)"
              placeholder="Tell us where your high school is located"
              className="col-span-2"
              onChange={(event) =>
                setUserData({ ...userData, schoolLocation: event.target.value })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 12px',
                  },
                },
              }}
              fullWidth={isParent ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />

            <SelectOption
              value={
                gradeMenuData.find((grade) => grade.grade === String(userData?.grade))
                  ?.id
              }
              options={gradeMenuData}
              label="Grade"
              className="col-span-2"
              onChange={(event) =>
                setUserData({
                  ...userData,
                  grade: gradeMenuData[event.target.value - 1]?.grade,
                })
              }
            />
          </div>
          <InputField
            id="outlined-name"
            value={userData.highschoolLink}
            placeholder="Usually Naviance or SCOIR - Ex: https://www.abc.com, www.abc.com"
            label="Link to High School Counselor system"
            helperText={error.field === 'highschoolLink' && (
              <p>
                <span className="text-red-500">{error?.message} Ex - https://www.abc.com, www.abc.com</span>
              </p>
            )}
            onChange={(event) => 
              setUserData({...userData, highschoolLink: event.target.value})
            }
          />
          <TextField
            id="outlined-name"
            value={userData?.address?.line1}
            label="Address line 1"
            onChange={(event) =>
              setUserData({
                ...userData,
                address: {
                  ...userData?.address,
                  line1: event.target.value,
                },
              })
            }
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                span: {
                  margin: '0px 8px',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-name"
            value={userData?.address?.line2}
            label="Address line 2"
            onChange={(event) =>
              setUserData({
                ...userData,
                address: {
                  ...userData?.address,
                  line2: event.target.value,
                },
              })
            }
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                span: {
                  margin: '0px 8px',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
          <div className="gap-4 flex flex-col sm:grid sm:grid-cols-3">
            <TextField
              value={userData?.address?.city}
              id="outlined-name"
              label="City"
              className="col-span-1"
              onChange={(event) =>
                setUserData({
                  ...userData,
                  address: {
                    ...userData?.address,
                    city: event.target.value,
                  },
                })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 4px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
            <TextField
              value={userData?.address?.state}
              id="outlined-name"
              label="State"
              className="col-span-1"
              onChange={(event) =>
                setUserData({
                  ...userData,
                  address: {
                    ...userData.address,
                    state: event.target.value,
                  },
                })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 4px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
            <TextField
              value={userData?.address?.postal_code}
              id="outlined-name"
              label="Postal Code"
              className="col-span-1"
              helperText={error.field === 'phoneNumber' && error.message}
              onChange={(event) =>
                setUserData({
                  ...userData,
                  address: {
                    ...userData?.address,
                    postal_code: event.target.value,
                  },
                })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 8px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </div>
          <TextField
            id="outlined-name"
            value={userData?.address?.country}
            className="self-start"
            label="Country"
            onChange={(event) =>
              setUserData({
                ...userData,
                address: {
                  ...userData.address,
                  country: event.target.value,
                },
              })
            }
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                span: {
                  margin: '0px 8px',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </div>
        {/* <Divider /> */}
      </div>
    </Dialog>
  );
};

export default MyAccountDetailsForm;
