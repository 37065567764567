import React, { useEffect, useState } from 'react';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';


const NicheRatingPopOver = ({
    rating = 0,
    points = 0,
    honors = 0,
    courses = 0,
    activities = 0,
}) => {

    const [utilitiesSummary, setUtilitiesSummary] = useState('');

    const ratingTiles = {
        0: {
            title: "Cosmic Spark",
            description: "You've identified your passion, now explore it!",
        },
        1: {
            title: "New Moon",
            description: "You've taken your first steps into your niche.",
        },
        2: {
            title: "Evolving World",
            description: "Your commitment to your niche is growing.",
        },
        3: {
            title: "Shining Star",
            description: "You're demonstrating significant dedication to your niche.",
        },
        4: {
            title: "Super Nova!",
            description: "Your achievements in your niche are impressive.",
        },
        5: {
            title: "Universily Awesome!",
            description: "You're a leader and innovator in your chosen field."
        }
    }

    useEffect(() => {
        setUtilitiesSummary(generateSummary());
    }, [courses, honors, activities, rating, points]);

    function generateSummary() {
        const parts = [];
    
        if (honors > 0) parts.push(`${honors} honor${honors > 1 ? 's' : ''}`);
        if (courses > 0) parts.push(`${courses} course${courses > 1 ? 's' : ''}`);
        if (activities > 0) parts.push(`${activities} activit${activities > 1 ? 'ies' : 'y'}`);
    
        return parts.length ? (parts.join(', ') + '.') : '';
    }
    


    return (
        <div className="flex flex-col items-center rounded-2xl">
            <div className='pb-4 pt-6 px-4 flex flex-col gap-4'>
                <div className="w-full">
                    <div className="flex items-center justify-center">
                        {[...Array(5)].map((_, index) => (
                        index < rating ? (
                                    <StarRoundedIcon style={{ color: '#FFA552' }} fontSize='medium'/> // Filled star
                                ) : (
                                    <StarOutlineRoundedIcon style={{ color: '#FFA552' }} fontSize='medium'/> // Unfilled star
                                )
                        ))}
                    </div>
                </div>
                <div className="space-y-1 text-center">
                    <h3 className="text-lg font-medium text-gray-800">{ratingTiles[rating]?.title}</h3>
                    <p className="text-gray-600 text-sm">
                        {ratingTiles[rating]?.description}
                    </p>
                </div>
            </div>
            <div className="text-center text-brand-blue-600 font-normal text-sm w-full py-1.5 px-6 bg-brand-blue-100">
                You earned <span className='font-semibold'>{rating} Stars</span> & <span className='font-semibold'>{points} Points</span> {utilitiesSummary?.length ? <span> for <span className='font-semibold'>{utilitiesSummary}</span></span> : ''}
            </div>
        </div>
    );
};

export default NicheRatingPopOver;
