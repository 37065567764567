import { useEffect, useState } from 'react';
import AddCollegeDialog from '../components/myApplications/AddCollegeDialog';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import {
  addStoryItemTypes,
  firebaseDataType,
  NOTIFICATION_ACTION_TYPES,
} from '../constants/other';
import {
  addNotificationItem,
  addStoryItem,
} from '../firebase/services/addServices';
import useAdminStore from '../stores/AdminStore';
import useApplicationStore from '../stores/ApplicationStore';
import { updateStoryItem } from '../firebase/services/updateServices';
import DeleteApplicationsDialog from '../components/myApplications/DeleteApplicationsDialog';
import { toastMessages } from '../constants/toaster';
import { AddCollegeApplicationDialog } from '../components/myApplications/AddCollegeApplicationDialog';
import useProfileStore from '../stores/ProfileStore';
import useStudentStore from '../stores/StudentStore';
import useTeamStore from '../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import {
  addTeamUniversilyToChatParticipants,
  addTeamUniversilyToReviewers,
  UTILITY_TYPES,
} from '../utils/utlityTypes';
import { removeNullUndefined } from '../utils/helper';

export const MyApplicationContainer = () => {
  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState({
    open: false,
    message: '',
    undoAction: false,
  });
  const {
    applications,
    isAddCollege,
    isCollegeId,
    setIsAddCollege,
    deleteCollegeId,
    isDeletingCollege,
    setDeleteCollegeId,
    setIsDeletingCollege,
    setShowDeleteSnackbar,
    showDeleteSnackbar,
    setIsAddApplicationRequirement,
    setIsCollegeId,
    isAddApplicationRequirement,
    isAddCollegeApplication,
    setIsAddCollegeApplication,
  } = useApplicationStore();
  const { collegeListData } = useAdminStore();
  const [selectedApplicationData, setSelectedApplicationData] = useState({});
  const isParent = useProfileStore((state) => state.isParent);
  const selectedStudent = useStudentStore((state) => state.selectedStudent);
  const teamStore = useTeamStore(
    useShallow((state) => ({
      selectedTeam: state.selectedTeam,
    }))
  );
  const profile = useProfileStore((state) => state.profile);
  const addCollege = async (data) => {
    let message = '';
    const collaborators = teamStore.selectedTeam?.teamMembers?.map(
      (member) => member?.uid
    );
    const channelMembers = addTeamUniversilyToChatParticipants(collaborators);
    const sharedWith = channelMembers.filter(
      (member) => member !== teamStore.selectedTeam.studentUID
    );
    //Make sure to have unique values in channelMembers
    const uniqueChannelMembers = Array.from(new Set(channelMembers));

    const collegeData = {
      ...data,
      sharedWith,
      owner: teamStore.selectedTeam?.studentEmail,
      createdBy: profile?.uid,
    };

    const findCollege = collegeListData?.find((item) => {
      return item?.collegeData?.id === collegeData?.collegeId;
    })?.collegeData;

    const collegeId = await addStoryItem(
      collegeData,
      addStoryItemTypes.APPLICATIONS
    );
    if (collegeId && findCollege) {
      // create notification for the college creation
      const notificationData = {
        utilityType: UTILITY_TYPES.Applications,
        utilityName: findCollege?.collegeName,
        utilityId: collegeId,
        teamId: teamStore?.selectedTeam?.id,
        actionType: NOTIFICATION_ACTION_TYPES.CREATED,
      };
      await addNotificationItem(notificationData);
      await addStoryItem(
        {
          isTopic: true,
          archivedBy: [],
          chatParticipants: removeNullUndefined(uniqueChannelMembers),
          topicName: 'Topic for College',
          utilityId: collegeId,
          topicType: 'Applications',
        },
        addStoryItemTypes.CHAT_ROOM
      );
    }
    if (applications?.length > 0) {
      message = toastMessages?.APPLICATION_ADDED;
    } else {
      message = toastMessages?.COLLEGE_ADDED;
    }
    setIsSuccessSnackbar({
      open: true,
      undoAction: false,
      message: message,
    });
  };

  const onDelete = () => {
    setShowDeleteSnackbar(true);
    setIsDeletingCollege(false);
    if (isAddApplicationRequirement) {
      setIsAddApplicationRequirement(false);
      setIsCollegeId(null);
    }
  };

  const hardDeleteCollege = async () => {
    // get the college data for the deleted college
    const collegeData = applications.find((app) => app.id === deleteCollegeId);
    const res = await updateStoryItem(
      { id: deleteCollegeId, deleteDocument: true },
      addStoryItemTypes.APPLICATIONS
    );
    if (res && collegeData) {
      // create notification for the college deletion
      const notificationData = {
        utilityType: UTILITY_TYPES.Applications,
        utilityName: collegeData?.collegeData?.collegeName,
        utilityId: deleteCollegeId,
        teamId: teamStore.selectedTeam?.id,
        actionType: NOTIFICATION_ACTION_TYPES.DELETED,
      };
      await addNotificationItem(notificationData);
    }
    setDeleteCollegeId(null);
    setShowDeleteSnackbar(false);
    setDeleteCollegeId(null);
  };

  useEffect(() => {
    if (deleteCollegeId && !showDeleteSnackbar) {
      hardDeleteCollege();
    }
    //eslint-disable-next-line
  }, [showDeleteSnackbar]);

  useEffect(() => {
    setSelectedApplicationData(
      applications?.find((app) => app?.collegeId === isCollegeId)
    );
  }, [isCollegeId, applications]);

  const addApplicationDetails = async (formData, section, action) => {
    let message = '';
    await updateStoryItem(
      { id: selectedApplicationData.id, ...formData },
      addStoryItemTypes.APPLICATIONS
    );
    switch (section) {
      case 'interview':
        message = `${toastMessages.INTERVIEW} ${action}.`;
        break;
      case 'letters':
        message = `${toastMessages.RECOMMENDATION_LETTERS} ${action}.`;

        break;
      case 'transcript':
        message = `${toastMessages.TRANSCRIPTS} ${action}.`;

        break;
      default:
        break;
    }

    setIsSuccessSnackbar({
      open: message && true,
      message: message,
    });
  };

  return (
    <>
      <AddCollegeApplicationDialog
        title={
          !selectedApplicationData?.applicationPlatformName
            ? `Application for ${selectedApplicationData?.collegeData?.collegeName}`
            : 'Edit Application Platform'
        }
        applicationData={selectedApplicationData}
        open={isAddCollegeApplication}
        onContinue={async (formData) => {
          setIsAddCollegeApplication(false);
          await addApplicationDetails(formData);
          !selectedApplicationData?.applicationPlatformName &&
            setIsAddApplicationRequirement(true);
        }}
        onCancel={() => {
          setIsCollegeId(null);
          setIsAddCollegeApplication(false);
        }}
      />
      <SuccessSnackbar
        message={
          isSuccessSnackbar.open
            ? isSuccessSnackbar.message
            : 'Your college has been deleted'
        }
        open={isSuccessSnackbar.open || showDeleteSnackbar}
        autoHideDuration={6000}
        onClose={async () => {
          if (deleteCollegeId) {
            await hardDeleteCollege();
          }
          setIsSuccessSnackbar({
            open: false,
            message: '',
            undoAction: false,
          });
        }}
        actionName={showDeleteSnackbar ? 'Undo' : ''}
        onAction={() => {
          setShowDeleteSnackbar(false);
          setIsDeletingCollege(false);
          setDeleteCollegeId(null);
        }}
      />
      <AddCollegeDialog
        open={isAddCollege}
        collegeList={collegeListData?.filter(
          (item) => !item?.collegeData?.deleteDocument
        )}
        title="Add College"
        continueText="Add"
        onContinue={async (data) => {
          await addCollege(data);
          setIsAddCollege(false);
        }}
        onCancel={() => setIsAddCollege(false)}
      />
      <DeleteApplicationsDialog
        open={isDeletingCollege}
        maxWidth="xs"
        onContinue={onDelete}
        onCancel={() => {
          setDeleteCollegeId(null);
          setIsDeletingCollege(false);
        }}
      />
    </>
  );
};
