import Apply_Texas from '../assets/Apply_Texas.png';
import California_State_University from '../assets/California_State_University.png';
import Coalition from '../assets/Coalition.png';
import Common_App from '../assets/Common_App.png';
import Suny from '../assets/Suny.png';
import UC from '../assets/UC.png';
import { ReactComponent as CommonAppIcon } from '../assets/svgs/common-app.svg';
import { ReactComponent as CoalitionAppIcon } from '../assets/svgs/coalition-app.svg';
import { ReactComponent as UCIcon } from '../assets/svgs/universityOfCalifornia.svg';
import { ReactComponent as NewYorkUni } from '../assets/svgs/newYorkUniversity.svg';
import { ReactComponent as CaliStateUni } from '../assets/svgs/californiaStateUniversity.svg';
import { ReactComponent as ApplyTexas } from '../assets/svgs/applyTexas.svg';
import { ReactComponent as PedalBikeIcon } from '../assets/svgs/bicycle.svg';
import { ReactComponent as TrophyIcon } from '../assets/svgs/trophy.svg';
import { ReactComponent as BusinessCenterIcon } from '../assets/svgs/briefcase.svg';
import { ReactComponent as ProfileIcon } from '../assets/svgs/profileIcon.svg';
import { ReactComponent as InProgressIcon } from '../assets/svgs/InProgress.svg';
import { ReactComponent as NotStartedIcon } from '../assets/svgs/NotStarted.svg';
import { ReactComponent as CompletedIcon } from '../assets/svgs/Completed.svg';
import { ACTIVITIES, COURSES, HONORS, NICHE } from '../navigation/constants';
import { ReactComponent as NotRequiredIcon } from '../assets/svgs/notRequired.svg';

export const SITE_TITLE = 'Universily';

export const addStoryItemTypes = {
  // TODO Add Story Item type here
  NICHE: 'niche',
  COURSE: 'course',
  ACTIVITY: 'activity',
  ACTIVITY_TYPES: 'activitytypes',
  HONOR: 'honor',
  HONOR_TYPES: 'honortypes',
  REVIEWER: 'reviewer',
  TEST_SCORE: 'testScores',
  GPA_SCORES: 'gpaScores',
  TEST_TARGETS: 'testTargets',
  PROFILE: 'profile',
  APPLICATION_REQUIREMENT: 'applicationRequirement',
  CHAT_ROOM: 'chatRooms',
  CHAT_MESSAGES: 'chatMessages',
  REFERRAL: 'referral',
  ESSAY: 'essay',
  APPLICATION_TEMPLATES: 'applicationTemplates',
  COLLEGE_LIST: 'collegesList',
  APPLICATIONS: 'applications',
  REVIEWER_TEAM: 'reviewerTeam',
};

export const firebaseDataType = {
  METADATA: 'metaData',
  STUDENT_DATA: 'studentData',
};

export const googleDriveFolders = {
  UNIVERSILY: 'Universily',
  HONOR: 'Honor',
  ACTIVITY: 'Activity',
  ESSAY: 'Essay',
};

export const templateType = {
  ACTIVITY: 'activity',
  HONOR: 'honor',
  PERSONAL_ESSAY: 'personalEssay',
  COMMUNITY_ESSAY: 'communityEssay',
  ACADEMIC_ESSAY: 'academicEssay',
  WHY_HERE_ESSAY: 'whyHereEssay',
  ARGUMENT_ESSAY: 'argumentEssay',
  ADDITIONAL_ESSAY: 'additionalInformationEssay',
  EXTRACURRICULAR_ESSAY: 'extracurricularEssay,',
};

const baseMime = 'application/vnd.google-apps';

export const googleDriveMimeTypes = {
  FOLDER: baseMime + '.folder',
  GOOGLE_DOC: baseMime + '.document',
};

export const metadataUniversily = {
  whereSubmittedTypesForEssay: [
    {
      value: 'collegeAdmissionsWebsite',
      label: 'College Admissions Website',
    },
    {
      value: 'commonAppSupplement',
      label: 'Common App Supplement',
    },
  ],
};

export const applicationTags = [
  {
    id: '1',
    label: 'Apply Texas',
    applicationPlatform: 'apply-texas',
    logo: <ApplyTexas />,
    link: 'https://www.applytexas.org',
  },
  {
    id: '2',
    label: 'California State University',
    applicationPlatform: 'ca-state-university-applications',
    logo: <CaliStateUni />,
    link: 'https://www.calstate.edu/apply/Pages/default.aspx',
  },
  {
    id: '3',
    label: 'Coalition for College',
    logo: <CoalitionAppIcon />,
    applicationPlatform: 'coalition-for-college',
    link: 'https://www.coalitionforcollegeaccess.org',
  },
  {
    id: '4',
    label: 'College Website',
    applicationPlatform: 'college-website',
  },
  {
    id: '5',
    label: 'Common App',
    logo: <CommonAppIcon />,
    applicationPlatform: 'common-app',
    link: 'https://www.commonapp.org',
  },
  {
    id: '6',
    label: 'State University of New York',
    applicationPlatform: 'state-university-of-new-york',
    logo: <NewYorkUni />,
    link: 'https://www.suny.edu/applysuny/',
  },
  {
    id: '7',
    label: 'University of California',
    logo: <UCIcon />,
    applicationPlatform: 'university-of-ca-admissions',
    link: 'https://admission.universityofcalifornia.edu/apply-now.html',
  },
];

export const supplementalTags = [
  {
    id: '8',
    label: 'Slide Room',
    logo: <></>,
    applicationPlatform: 'slideRoom',
    link: '',
  },
  {
    id: '9',
    label: 'Other',
    logo: <></>,
    applicationPlatform: 'other',
    link: '',
  },
];

export const applicationTagsForEssays = [
  {
    id: '1',
    label: 'Apply Texas',
    applicationPlatform: 'apply-texas',
    icon: Apply_Texas,
  },
  {
    id: '3',
    label: 'Coalition for College',
    applicationPlatform: 'coalition-for-college',
    icon: Coalition,
  },

  {
    id: '5',
    label: 'Common App',
    applicationPlatform: 'common-app',
    icon: Common_App,
  },
  {
    id: '6',
    label: 'State University of New York',
    applicationPlatform: 'state-university-of-new-york',
    icon: Suny,
  },
  {
    id: '7',
    label: 'University of California',
    applicationPlatform: 'university-of-ca-admissions',
    icon: UC,
  },
];
export const selectOptionData = [
  {
    id: 1,
    value: 'required',
    label: 'Required',
  },
  {
    id: 2,
    value: 'optional',
    label: 'Optional',
  },
  {
    id: 3,
    value: 'not-accepted',
    label: 'Not Accepted',
  },
];

export const interviewOptionData = [
  {
    id: 1,
    value: 'required',
    label: 'Required',
  },
  {
    id: 2,
    value: 'optional',
    label: 'Optional',
  },
  {
    id: 3,
    value: 'not-offered',
    label: 'Not Offered',
  },
];

export const essayCategory = [
  {
    tag: 'personalEssay',
    name: 'Personal Essay',
  },
  {
    tag: 'communityEssay',
    name: 'Community Essay',
  },
  {
    tag: 'academicEssay',
    name: 'Academic Essay',
  },
  {
    tag: 'whyHereEssay',
    name: 'Why Here',
  },
  {
    tag: 'argumentEssay',
    name: 'Argument Essay',
  },
  {
    tag: 'additionalInformationEssay',
    name: 'Additional Information',
  },
  {
    tag: 'extracurricularEssay',
    name: 'Extracurricular Essay',
  },
];

export const roundOptions = [
  {
    id: 1,
    value: 'early-decision-I',
    label: 'Early Decision |',
  },
  {
    id: 2,
    value: 'early-decision-II',
    label: 'Early Decision ||',
  },
  {
    id: 3,
    value: 'early-action-restricted-I',
    label: 'Early Action Restricted |',
  },
  {
    id: 4,
    value: 'early-action-not-restricted-I',
    label: 'Early Action Non Restricted |',
  },
  {
    id: 5,
    value: 'early-action-restricted-II',
    label: 'Early Action Restricted ||',
  },
  {
    id: 6,
    value: 'early-action-not-restricted-II',
    label: 'Early Action Non Restricted ||',
  },
  {
    id: 7,
    value: 'regular',
    label: 'Regular',
  },
  {
    id: 8,
    value: 'rolling',
    label: 'Rolling',
  },
];

export const sidebarList = [
  { id: '1002', label: 'Year and Term' },
  {
    id: '1003',
    label: 'Application Platform',
  },
  { id: '1004', label: 'Interview' },
  { id: '1005', label: 'Rounds' },
  { id: '1006', label: 'Recommendation Letters' },
  { id: '1007', label: 'Transcripts' },
  { id: '1008', label: 'Tests' },
  { id: '1009', label: 'Essays' },
  { id: '1010', label: 'Supplemental' },
];

export const transcriptYear = [
  {
    id: 1,
    value: '9to11',
    label: '9-11',
  },
  {
    id: 2,
    value: '12midYear',
    label: '12 mid year',
  },
  {
    id: 3,
    value: '12final',
    label: '12 final',
  },
];

export const transcriptSelectOptionData = [
  {
    id: 1,
    label: 'Counselor to submit official transcript',
    value: 'collegeRequiresCounselorToSubmitOfficialTranscript',
  },
  {
    id: 2,
    label: 'Self reported transcript',
    value: 'collegeAcceptSelfReportedTranscripts',
  },
  {
    id: 3,
    label: 'None',
    value: 'none',
  },
];

export const testSubmissionOptions = [
  {
    id: 1,
    label: 'College requires that test companies submit test scores',
    value: 'collegeRequiresScoresDirectlyFromTheTestsBoard',
  },
  {
    id: 2,
    label: 'College accepts self-reported scores',
    value: 'collegeAcceptSelfReportedSatActScores',
  },
  {
    id: 3,
    label: 'Not Accepted',
    value: 'not-accepted',
  },
];

export const MyApplicationEssayOption = [
  {
    id: 'ess011',
    label: 'Not Yet Started',
    color: '',
    subText: 'If you haven’t started writing or added your essay',
  },
  {
    id: 'ess022',
    label: 'In Progress',
    color: '#FC895E',
    subText: 'If you are composing/ writing currently in Universily',
  },
  {
    id: 'ess033',
    label: 'Under Review',
    color: '#8D73E4',
    subText: 'If shared with Parents/ Teachers & Counselors for feedback',
  },
  {
    id: 'ess044',
    label: 'Completed',
    color: '#2EC39F',
    subText: 'If you have completed writing and got it reviewed',
  },
  {
    id: 'ess055',
    label: 'Submitted',
    color: '#47C257',
    subText: 'If you have uploaded the essay to the application platform',
  },
  {
    id: 'ess066',
    label: 'Ignore',
    color: '#616675',
    subText: 'If you choose to skip this Essay',
  },
];

export const MyApplicationLettersOption = [
  {
    id: 'let011',
    label: 'Not Yet Requested',
    color: '',
  },
  {
    id: 'let022',
    label: 'Requested',
    color: '#FC895E',
  },
  {
    id: 'let033',
    label: 'Submitted',
    color: '#47C257',
    subText: 'If you have uploaded the letter to the application platform',
  },
  {
    id: 'let044',
    label: 'Ignore',
    color: '#616675',
    subText: 'If you choose to skip this letter',
  },
];

export const MyApplicationSupplementalOption = [
  {
    id: 'supp011',
    label: 'Not Yet Started',
    color: '',
    subText: 'If you haven’t started writing or added your Supplemental',
  },
  {
    id: 'supp022',
    label: 'Completed',
    color: '#2EC39F',
    subText: 'If you have completed writing and got it reviewed',
  },
  {
    id: 'supp033',
    label: 'Submitted',
    color: '#47C257',
    subText:
      'If you have uploaded the Supplemental to the application platform',
  },
  {
    id: 'supp044',
    label: 'Ignore',
    color: '#616675',
    subText: 'If you choose to skip this Supplemental',
  },
];

export const MyApplicationTranscriptOption = [
  {
    id: 'trans011',
    label: 'Not Yet Requested',
    color: '',
  },
  {
    id: 'trans022',
    label: 'Requested',
    color: '#FC895E',
  },
  {
    id: 'trans033',
    label: 'Submitted',
    color: '#47C257',
    subText: (
      <div>
        If it’s uploaded to <b>Naviance/SCOIR/Common App</b> etc.
      </div>
    ),
  },
  {
    id: 'trans044',
    label: 'Ignore',
    color: '#616675',
    subText: 'If you choose to skip this Transcript',
  },
];

export const MyApplicationTestOptions = [
  {
    id: 'test011',
    label: 'Not Yet Taken',
    color: '',
  },
  {
    id: 'test022',
    label: 'Taken',
    color: '#FC895E',
  },
  {
    id: 'test033',
    label: 'Submitted',
    color: '#47C257',
    subText:
      'If it’s uploaded on ACT/ SAT portal, OR self reported on the app platform etc',
  },
  {
    id: 'test044',
    label: 'Ignore',
    color: '#616675',
    subText: 'If you choose to skip this Test',
  },
];

export const collegeApplicationStatusList = [
  {
    id: 1,
    label: 'In Progress',
    value: 'in-progress',
    color: '#FC895E',
  },
  {
    id: 2,
    label: 'Submitted',
    value: 'submitted',
    color: '#47C257',
  },
  {
    id: 3,
    label: 'Accepted',
    value: 'accepted',
    color: '#30B28C',
  },
  {
    id: 4,
    label: 'Waitlisted',
    value: 'wait-listed',
    color: '#FCB017',
  },
  {
    id: 5,
    label: 'Rejected',
    value: 'rejected',
    color: '#FC5F64',
  },
  {
    id: 6,
    label: 'Withdrawn',
    value: 'withdrawn',
    color: '#616675',
  },
  {
    id: 7,
    label: 'Considering',
    value: 'not-applying',
    color: '#37CDFA',
  },
];

export const applicationTaskLabels = [
  {
    id: 0,
    label: 'Profile',
    value: 'profile',
    icon: <ProfileIcon />,
  },
  {
    id: 1,
    label: 'Honors',
    value: 'honor',
    icon: <TrophyIcon />,
  },
  {
    id: 2,
    label: 'Courses',
    value: 'course',
    icon: <BusinessCenterIcon />,
  },
  {
    id: 3,
    label: 'Activities',
    value: 'activity',
    icon: <PedalBikeIcon />,
  },
];

export const supplementTypes = [
  {
    id: 1,
    value: 'mediaFiles',
    label: 'Media Files',
  },
  {
    id: 2,
    value: 'questions',
    label: 'Questions',
  },
  {
    id: 3,
    value: 'other',
    label: 'Other',
  },
];

export const statusIndicatorLabels = [
  {
    id: 1,
    value: 'in-progress',
    label: 'In Progress',
    icon: <InProgressIcon className="shrink-0" />,
  },
  {
    id: 2,
    value: 'not-started',
    label: 'Not Started',
    icon: <NotStartedIcon className="shrink-0" />,
  },
  {
    id: 3,
    value: 'completed',
    label: 'Completed',
    icon: <CompletedIcon className="shrink-0" />,
  },
  {
    id: 4,
    value: 'not-required',
    label: 'Not Required',
    icon: <NotRequiredIcon className="shrink-0" />,
  },
];
export const learnVideo = [
  {
    id: 1,
    page: NICHE,
    keyword: 'Niches',
    link: 'https://www.youtube.com/watch?v=ffAVF1T0a2E',
  },
  {
    id: 2,
    page: ACTIVITIES,
    keyword: 'Activities',
    link: 'https://www.youtube.com/watch?v=7rRuKEXsGq8',
  },
  {
    id: 3,
    page: HONORS,
    keyword: 'Honors',
    link: 'https://www.youtube.com/watch?v=IKtXJsZwpSE',
  },
  {
    id: 4,
    page: COURSES,
    keyword: 'Courses',
    link: 'https://www.youtube.com/watch?v=5DZQCEcKppk',
  },
];

export const PLAN_TYPES = {
  MONTH: 'month',
  YEAR: 'year',
  UNLIMITED: 'unlimited',
};

export const CIO_SHARE_ITEM_TYPES = {
  Activities: {
    name: 'Activity',
    destinationLink: 'myStory/activities',
  },
  Honors: {
    name: 'Honor',
    destinationLink: 'myStory/honors',
  },
  Courses: {
    name: 'Course',
    destinationLink: 'myStory/courses',
  },
  Niche: {
    name: 'Niche',
    destinationLink: 'myStory/niche',
  },
  Test: {
    name: 'Test',
    destinationLink: 'myStory/tests',
  },
  Essay: {
    name: 'Essays',
    destinationLink: 'myEssay',
  },
  Applications: {
    name: 'Application',
    destinationLink: 'myColleges',
  },
  GPA: {
    name: 'GPA',
    destinationLink: 'myStory/gpaScores',
  },
};

export const NOTIFICATION_ACTION_TYPES = {
  CREATED: "CREATED",
  UPDATED: "UPDATED",
  DELETED: "DELETED",
}

export const TEST_SCORE_TYPES = {
  TEST_TARGETS: 'TEST_TARGETS',
  TEST_SCORES: 'TEST_SCORES',
}

export const STUDENT_GRADES = {
  FRESHMAN: 9,
  SOPHOMORE: 10,
  JUNIOR: 11,
  SENIOR: 12,
} 

export const HONOR_LEVELS = {
  SCHOOL: 'School',
  CITY_COMMUNITY: 'City/Community',
  STATE_REGIONAL: 'State/Regional',
  NATIONAL: 'National',
  INTERNATIONAL: 'International'
}